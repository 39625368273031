<template>
  <div class="mysite">
    <div class="search-wrap flex" @click="searchClick"><van-icon size="0.3rem" name="search" /><span>输入站点名称/运营人员/站长</span></div>
    <div v-if="nosite"
         class="no-site">暂无站点</div>
    <div v-else
         class="site-list">
      <threeLevelMenu @oneChange="oneChange" @twoChange="twoChange" @threeChange="threeChange" v-if="!isFromMine" style="margin: 0 -0.3rem;"></threeLevelMenu>
      <div class="width_full">
        <div class="flex justify_content_space_between">
          <div :class="{'btnBgSelect':plainFlag[0],'btnBgNormal':!plainFlag[0]}"
               @click="siteClick(ALLCOUNT, '', 0)">
            <div class="btnNumTitle"
                 :style="{'color': plainFlag[0] ? 'white': '#96A1A8'}">{{ALLCOUNT}}</div>
            <div class="btnInfoTitle"
                 :style="{'color': plainFlag[0] ? 'white': '#96A1A8'}">全部</div>
          </div>
          <div :class="{'btnBgSelect':plainFlag[1],'btnBgNormal':!plainFlag[1]}"
               @click="siteClick(INSPECTION, 'INSPECTION_', 1)">
            <div class="btnNumTitle"
                 :style="{'color': plainFlag[1] ? 'white': '#96A1A8'}">{{INSPECTION}}</div>
            <div class="btnInfoTitle"
                 :style="{'color': plainFlag[1] ? 'white': '#96A1A8'}">考察中</div>
          </div>
          <div :class="{'btnBgSelect':plainFlag[2],'btnBgNormal':!plainFlag[2]}"
               @click="siteClick(JOIN, 'JOIN_', 2)">
            <div class="btnNumTitle"
                 :style="{'color': plainFlag[2] ? 'white': '#96A1A8'}">{{JOIN}}</div>
            <div class="btnInfoTitle"
                 :style="{'color': plainFlag[2] ? 'white': '#96A1A8'}">加盟中</div>
          </div>

        </div>
      </div>
      <div class="width_full"
           style="margin: 10px 0;">
        <div class="flex justify_content_space_between">
          <div :class="{'btnBgSelect':plainFlag[3],'btnBgNormal':!plainFlag[3]}"
               @click="siteClick(JOINED, 'JOINED', 3)">
            <div class="btnNumTitle"
                 :style="{'color': plainFlag[3] ? 'white': '#96A1A8'}">{{JOINED}}</div>
            <div class="btnInfoTitle"
                 :style="{'color': plainFlag[3] ? 'white': '#96A1A8'}">已加盟</div>
          </div>
          <div :class="{'btnBgSelect':plainFlag[4],'btnBgNormal':!plainFlag[4]}"
               @click="siteClick(SIGNED, 'SIGNED', 4)">
            <div class="btnNumTitle"
                 :style="{'color': plainFlag[4] ? 'white': '#96A1A8'}">{{SIGNED}}</div>
            <div class="btnInfoTitle"
                 :style="{'color': plainFlag[4] ? 'white': '#96A1A8'}">已签约</div>
          </div>
          <div :class="{'btnBgSelect':plainFlag[5],'btnBgNormal':!plainFlag[5]}"
               @click="siteClick(FAILCOUNT, 'JOIN_FAIL,SIGN_FAIL', 5)">
            <div class="btnNumTitle"
                 :style="{'color': plainFlag[5] ? 'white': '#96A1A8'}">{{FAILCOUNT}}</div>
            <div class="btnInfoTitle"
                 :style="{'color': plainFlag[5] ? 'white': '#96A1A8'}">未通过</div>
          </div>
        </div>
      </div>
      <div v-if="isShow_SiteList_ToBeAudited"
           class="check-pending"
           @click="checkPendingClick">
        <div class="check-pending-title">查看待审核站点( {{pendingStationNum}} )</div>
        <van-icon name="arrow"
                  style="margin-right: 20px"
                  color="#CCCCCC"
                  size="16px"></van-icon>
      </div>

      <div class="site-item_list">
        <van-pull-refresh v-model="isLoading"
                      @refresh="onRefresh">
        <van-list v-model="loading"
                  :finished="finished"
                  finished-text="没有更多了"
                  @load="onLoad">
          <div class="site-item VDS-ShadowBg_home flex"
               v-for="(item, index) in siteList"
               :key="index">
            <img v-if="item.masterImg" class="sitezz-img" :src="item.masterImg">
            <img v-else-if="item.stationTagCode == 'FAILURE'" class="sitezz-img" :src="sitefailure">
            <img v-else class="sitezz-img" :src="sitezz">
            <div class="width_full">
              <div class="site-item_right  flex">
                <div class="site-item_one flex">
                  <div class="flex "
                       style="height: 20px">
                    <div class="stationName"
                         style="height: 20px"  v-if="item.stationType && item.stationType != '---' && item.hasMonitor != 0">{{item.stationName | ellipsis8 }}</div>
                    <div class="stationName"
                         style="height: 20px"  v-else-if="item.stationType && item.stationType != '---'">{{item.stationName | ellipsis10 }}</div>
                    <div class="stationName"
                         style="height: 20px" v-else>{{item.stationName | ellipsis10 }}</div>
                    <van-image v-if="item.hasMonitor != 0"
                               :src="item.monitorStatus != 0 ? require('@/assets/img/site/live_onLine.png') : require('@/assets/img/site/live_outLine.png')"
                               width="20"
                               height="20"
                               style="margin-top: -2px;margin-left: 3px"></van-image>
                  </div>
                  <div class="site-item_status"
                       v-if="item.stationType && item.stationType != '---'"
                       :class="{
                                    'site-item_status_one':item.stationType == '优质站点',
                            'site-item_status_two':item.stationType == '问题站点',
                            'site-item_status_three':item.stationType == '普通站点',
                            'site-item_status_four':item.stationType == '低效站点',
                            'site-item_status_three':item.stationType == '普通站点+',
                            'site-item_status_five':item.stationType == '优质站点+',}">
                    {{item.stationType}}
                  </div>
                </div>
                <div class="stationLocate">{{item.stationLocate}}</div>
                <div class="flex">
                  <div class="site-item_tag"
                       v-if="item.stationTag" :class="{'site-item_failure': item.stationTagCode == 'FAILURE'}">{{item.stationTag}}</div>
                  <div class="site-item_tag site-item_performanceRange"
                       v-if="item.performanceRange && item.performanceRange != '---'">{{item.performanceRange}}</div>
                  <div class="site-item_tag" v-if="item.stationStatus"
                    :class="{
                      'site-item_stationStatus_one':item.stationStatus == '考察中',
                      'site-item_stationStatus_two':item.stationStatus == '加盟中',
                      'site-item_stationStatus_three':item.stationStatus == '已加盟',
                      'site-item_stationStatus_four':item.stationStatus == '已签约',
                      'site-item_stationStatus_five': (item.stationStatus == '签约未通过' || item.stationStatus == '加盟未通过' ) }"
                  >{{item.stationStatus}}</div>
                  <!-- :class="{ 'borderColor' : borderColor, 'fontColor': fontColor}" -->
                </div>
              </div>
              <div v-if="isShow_SiteList_ToBeAudited" style="background-color: #F2F2F2;width: 100%;height: 1px;margin-top: 12px"></div>
              <div style="width: 100%;height: 24px;margin-top: 12px;">
                <div class="toStation" v-if="(userRole == 0 || userRole == 3 )&& item.stationStatusCode == 'INSPECTION_'" @click="editClick(item.stationNo)">编辑站点</div>
                <div class="toStation" v-else @click="siteDetailClick(item.stationNo, item.marketerNo, item.stationStatusCode)">查看站点</div>

                <div v-if="item.hasMonitor != 0"
                     @click.stop="monitoringPageClick(item.stationNo)"
                     class="toLive">查看监控</div>
              </div>
            </div>

          </div>
        </van-list>
        </van-pull-refresh>
      </div>
    </div>
    <div class="nextBtn flex" >
      <van-button class="publicBtnActive left-btn"
                  @click="psClick"
                  v-if="isShow_SiteList_PerformanceStatistics">业绩统计</van-button>
      <van-button class="publicBtnActive"
                  @click="newSiteClick"
                  style="margin-right: 0.24rem;"
                  v-if="isShow_SiteList_NewSite">规范检查</van-button>
      <van-button class="publicBtnActive"
                  @click="putInto"
                  v-if="isShow_SiteList_InputManagement">投入管理</van-button>
    </div>

  </div>
</template>

<script>
import { marketerMySite, getMySitePage, getPendingStationList } from '@/utils/api'
import common from '@/utils/common'
import {mapGetters} from 'vuex'
import {Home_Page, Mine_Page} from "../../utils/constantNum";
import {Page_Audit_Mysite, Page_SiteList_Home, Page_SiteList_Mine} from "../../store/mutation-types";
import threeLevelMenu from '@/components/threeLevelMenu'
import {uwStatisticAction} from "../../utils/util";
export default {
  components: {
    threeLevelMenu
  },
  computed:{
    ...mapGetters([
            'isShow_SiteList_ToBeAudited',
            'isShow_SiteList_PerformanceStatistics',
            'isShow_SiteList_NewSite',
            'isShow_SiteList_InputManagement'
    ])
  },
  data () {
    return {
      nosite: true,
      logoImg: require('../../assets/img/logo_f.png'),
      siteList: [],
      isAudit: common.getItem('isAudit'),
      userRole: common.getItem('userRole'),
      ALLCOUNT: 0,
      FAILCOUNT: 0,
      INSPECTION: 0,
      JOIN: 0,
      SIGNED: 0,
      JOINED: 0,
      plainFlag: [true, false, false, false, false, false],
      pendingStationNum: 0,
      isFromMine: false,
      normalImg: require('@/assets/img/site/mySite_normol.png'),
      selectImg: require('@/assets/img/site/mySite_selete.png'),
      performanceStatistics: require('@/assets/img/site/performance_statistics.png'),
      loading: false,
      finished: false,
      pageIndex: 1,
      pageSize: 10,
      stationStatus: '',
      isLoading: false,
      sitezz: require('@/assets/img/site/sitezz.png'),
      sitefailure: require('@/assets/img/site/sitefailure.png'),
      firstFlag: true,
      province: '',
      city: '',
      county: '',
    }
  },
  filters: {
    ellipsis8(value) {
      if (!value) return ''
      if (value.length > 8) {
        return value.slice(0, 8) + '...'
      }
      return value
    },
    ellipsis10(value) {
      if (!value) return ''
      if (value.length > 15) {
        return value.slice(0, 15) + '...'
      }
      return value
    },
  },
  methods: {
    editClick(stationNo) {
      this.$router.push({name: 'newsiteList', query:{stationNo: stationNo}})
    },
    searchClick(){
      this.$router.push({name: 'searchsite', query: {pageType: this.$route.query.pageType}})
    },
    oneChange(val){
      this.pageIndex = 1
      this.province = val
      this.city = ''
      this.county = ''
      this.togetMySitePage(true)
      this.getSiteList()
    },
    twoChange(val) {
      this.pageIndex = 1
      this.city = val
      this.county = ''
      this.togetMySitePage(true)
      this.getSiteList()
    },
    threeChange(val) {
      this.pageIndex = 1
      this.county = val
      this.togetMySitePage(true)
      this.getSiteList()
    },
    onRefresh() {
      this.isLoading = false
      this.siteList = []
      this.pageIndex = 1
      this.togetMySitePage()
    },
    onLoad () {
      this.togetMySitePage()
    },
    togetMySitePage (flag) {
      let status = this.stationStatus ? this.stationStatus : ''
      if(flag){
        this.siteList = []
        this.pageIndex = 1
      }
      getMySitePage({
        uniqueNo: common.getItem('uniqueNo'),
        stationStatus: status,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        pageType: this.$route.query.pageType,
        province: this.province,
        city: this.city,
        county: this.county,
      }).then(res => {
        this.loading = false;
        if (res.code == 200) {
          if (res.data && res.data.pageModel && res.data.businessCode == 200) {
            this.finished = !res.data.pageModel.hasNextPage;
            if (this.pageIndex == 1) {
              this.siteList = res.data.pageModel.list;
            } else {
              this.siteList = this.siteList.concat(res.data.pageModel.list);
            }
            if (!this.finished) {
              this.pageIndex++;
            }
             var baseUrl = process.env.NODE_ENV != 'production' ? 'http://124.70.167.116:20081' + '/file/' : window.location.origin + '/file/'
            var tokenUrl = '?Authorization=' + common.getItem('wtToken')

            for(let obj of this.siteList){
              if(obj.masterImg && obj.masterImg.indexOf('Authorization') < 0){
                obj.masterImg = baseUrl + obj.masterImg  + tokenUrl
              }
            }
          }
        }
      })
    },
    siteDetailClick (stationNo, marketerNo, stationStatusCode) {
      // 考察中直接跳转 提交加盟页面
      if (stationStatusCode == 'INSPECTION_') {

        if (marketerNo == common.getItem('uniqueNo')) { // 是自己建的
          if(this.userRole == 4) {// 高级用户
            this.$router.push({name: 'newsiteList', query:{stationNo: stationNo}})
          }else{
            this.$store.dispatch('keepAliveState/addKeepAliveAction', 'sitedetail')
            common.setItem("pageType",this.$route.query.pageType)
            this.$router.push({ name: 'sitedetail', query: { stationNo: stationNo,pageType:this.$route.query.pageType } })
          }


        } else {
          if(this.userRole == 4) {// 高级用户
            this.$router.push({name: 'newsiteList', query:{stationNo: stationNo}})
          }else{
            this.$router.push({ name: 'audit', query: { stationNo: stationNo,from:Page_Audit_Mysite } })
          }
        }
      } else {
        if(stationStatusCode == 'SIGNED') {
          this.$router.push({ name: 'signed', query: { stationNo: stationNo} })
        }else{
          this.$router.push({ name: 'waitingSign', query: { stationNo: stationNo, stationStatusCode: stationStatusCode } })
        }
      }
    },
    siteClick (num, status, index) {
      this.stationStatus = status
      // 选中按钮颜色变化
      for (let [i, obj] of this.plainFlag.entries()) {
        this.plainFlag[i] = false
      }
      this.plainFlag[index] = true
      this.siteList = []
      this.pageIndex = 1
      this.onLoad()
    },
    newSiteClick () {
      this.$store.dispatch('keepAliveState/addKeepAliveAction', 'checkList')
      // this.$router.push('/ns')
      this.$router.push('/ruleChecking/checkList')
    },
    monitoringPageClick (stationNo) {
      this.$router.push({ name: 'monitoringPage', query: { 'stationNo': stationNo } })
    },
    checkPendingClick () {
      this.$router.push({ name: 'checkpending' })
    },
    getSiteList (flag) {
      marketerMySite({
        token: common.getItem('wtToken'),
        uniqueNo: common.getItem('uniqueNo'),
        pageType: this.$route.query.pageType,
        province: this.province,
        city: this.city,
        county: this.county,
      }).then(res => {
        if (res.code == 200) {
          if (res.data.ALL_COUNT != undefined) this.ALLCOUNT = res.data.ALL_COUNT
          if (res.data.FAIL_COUNT != undefined) this.FAILCOUNT = res.data.FAIL_COUNT
          if (res.data.INSPECTION_COUNT != undefined) this.INSPECTION = res.data.INSPECTION_COUNT
          if (res.data.JOIN_COUNT != undefined) this.JOIN = res.data.JOIN_COUNT
          if (res.data.SIGNEDCOUNT != undefined) this.SIGNED = res.data.SIGNEDCOUNT
          if (res.data.JOINEDCOUNT != undefined) this.JOINED = res.data.JOINEDCOUNT
          if (this.ALLCOUNT || this.FAILCOUNT || this.INSPECTION || this.JOIN || this.SIGNED || this.JOINED) {
            this.nosite = false
          } else {
            flag ? this.nosite = true : this.nosite = false
          }
        }
      })
    },
    getPendingStationList () {
      getPendingStationList({
        token: common.getItem('wtToken'),
        pageIndex: 1,
        pageSize: 20
      }).then(res => {
        if (res.code == 200) {
          if (res.data && res.data.data && res.data.businessCode) {
            this.pendingStationNum = res.data.data.totalRecord;
          } else {
            this.pendingStationNum = 0;
          }
        }
      })
    },
    psClick () {
      this.$router.push({ name: 'psInfo' })
    },
    putInto(){
      this.$router.push('/site/putInto')
    }
  },
  mounted () {
    if (this.$route.query.pageType == Mine_Page) {
      this.isFromMine = true;
      this.$store.dispatch('permission/setPermissionAction',Page_SiteList_Mine )
      uwStatisticAction("/mysite",'站点管理（我的）')
    } else {
      this.isFromMine = false;
      this.$store.dispatch('permission/setPermissionAction',Page_SiteList_Home )
      uwStatisticAction("/mysite",'站点管理（首页）')
    }
    if (this.$route.query.role) { //  0：市场人员 1：站长
      this.getSiteList(this.firstFlag);
      if(this.isAudit == 1) {
        this.getPendingStationList();
      }
    } else {
      this.$store.dispatch('keepAliveState/addKeepAliveAction', 'login')
      this.$router.push({ name: 'login', query: { redirect: 'mysite',pageType:this.$route.query.pageType} })
    }
    if (this.$route.query.pageType === Home_Page){
      document.title = '站点管理'
    }else if (this.$route.query.pageType === Mine_Page){
      document.title = '我的站点'
    }

  },
  beforeRouteLeave (to, from, next) {
    if (to.name == 'NewSite') {
      // to.meta.keepAlive = false;
    }
    next()
  },
}
</script>

<style lang="less" scoped>
.mysite {
  min-height: 100%;
  margin: 0 0.2rem 0.2rem;
  .flex {
    display: flex;
  }
  .overlay{
    width: 100%;
    min-height: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    position: absolute;
    z-index: 10;
    left: 0;
  }
  .icon-triangle{
    width: 0;
    height: 0;
    border-left: 0.06rem solid transparent;
    border-right: 0.06rem solid transparent;
    border-top: 0.12rem solid #3F7C53;
  }
  .search-wrap{
    height: 0.6rem;
    line-height: 0.6rem;
    padding: 0 0.24rem;
    border-radius: 0.3rem;
    background: #F0F0F0;
    margin: 0.24rem 0.1rem 0;
    font-size: 0.26rem;
    color: #999999;
    align-items: center;
    span{
      margin-left:0.1rem;
    }
  }
  .top-select{
    height: 0.8rem;
    background: #fff;
    position: relative;
    z-index: 10;
    margin: 0 -0.3rem;
    .show-val{
      height: 0.8rem;
      line-height: 0.8rem;
      width: 100%;
      text-align: center;
      align-items: center;
      font-size: 0.26rem;
      display: flex;
      justify-content: center;
      .item{
        padding-right: 0.1rem;
        color: #3F7C53;
      }
    }
    .list{
      z-index: 100;
      position: absolute;
      width: 100%;
      background: #fff;
      left: 0;
      top: 0;
      li{
        height: 0.7rem;
        line-height: 0.7rem;
        padding: 0 0.3rem;
      }
      .selected{
        color:#3F7C53;
      }
    }
  }
  .block{
    flex: 1;
  }
  .new-site {
    width: 100%;
    background: #3f7c53;
    border-color: #3f7c53;
    color: white;
  }
  .sitezz-img{
    width: 1.36rem;
    height: 1.36rem;
    flex: 0 0 1.36rem;
    border-radius: 0.08rem;
  }
  .no-site {
    margin-top: 0.2rem;
    width: 100%;
    height: 1.5rem;
    line-height: 1.5rem;
    text-align: center;
    background: #fff;
    color: #bbb;
  }
  .check-pending {
    background: #f5f5f5;
    color: #3f7c53;
    text-align: center;
    height: 31px;
    background-color: #f5f5f5;
    margin-top: 18px;
    border-radius: 0.4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .check-pending-title {
      margin-left: 20px;
      font-size: 13px;
      height: 31px;
      line-height: 32px;
    }
  }
  .site-list {
    margin-top: 0.2rem;
    margin-bottom: 0.88rem;
    .status-list {
      .van-button {
        flex: 1;
      }
      .van-button--normal {
        padding: 0 !important;
      }
    }
    .site-item_list {
      padding-bottom: 55px;
      padding-top: 5px;
      .site-item {
        margin-top: 12px;
        border-radius: 4px;
        padding: 0.3rem 0.3rem 0.4rem;
        .logo-img {
          width: 1.5rem;
          height: 1.5rem;
          border-radius: 0.3rem;
        }
        .site-item_right {
          display: flex;
          width: 100%;
          flex-direction: column;
          justify-content: space-between;
          .site-item_one {
            height: 16px;
            line-height: 16px;
            justify-content: space-between;
            align-items: flex-start;
            .stationName {
              margin-left: 10px;
              font-size: 15px;
              font-weight: bold;
              color: #333333;
            }
          }
          .site-item_tag {
            margin-left: 8px;
            color: #3f7c53;
            border: solid 1px #D9E4DD;
            height: 20px;
            line-height: 20px;
            border-radius: 4px;
            font-size: 12px;
            padding: 0 10px;
          }
          .site-item_performanceRange{
            color: #3F7C53;
            border-color: #D9E4DD;
          }
          .site-item_status {
            font-size: 12px;
            height: 20px;
            line-height: 20px;
            padding: 0 15px;
            border-radius: 2px;
          }
          .site-item_failure{
            color: #999;
            border-color: rgba(0,0,0,0.1);
          }
          .site-item_status_one {
            color: #56cb8f;
            background-color: #e0f5eb;
          }
          .site-item_status_two {
            color: #f4664a;
            background-color: #ffeae6;
          }
          .site-item_status_three {
            color: #3874db;
            background-color: rgba(56, 116, 219, 0.1);
          }
          .site-item_status_four {
            color: #999999;
            background-color: rgba(153, 153, 153, 0.1);
          }
          .site-item_status_five{
            color:#fff;
            background: linear-gradient(155deg, #FCEAAE 0%, #E98D14 100%);
          }
          .site-item_stationStatus_one{
            color: #F6AA2E;
            border-color: #FFEFCB;
          }
          .site-item_stationStatus_two{
            color: #F6AA2E;
            border-color: #FFEFCB;
          }
          .site-item_stationStatus_three{
            color: #56CB8F;
            border-color: #E0F5EB;
          }
          .site-item_stationStatus_four{
            color: #56CB8F;
            border-color: #E0F5EB;
          }
          .site-item_stationStatus_five{
            color: #F4664A;
            border-color: #FFEAE6 ;
          }
          .stationLocate {
            margin-left: 10px;
            font-size: 13px;
            color: #999999;
            padding: 0.1rem 0 0.1rem;
            word-wrap: break-word;
            word-break: break-all;
          }
        }
      }
    }
  }
  .footer {
    width: 100%;
    height: 0.8rem;
    line-height: 0.8rem;
    text-align: center;
    background: transparent;
    position: absolute;
    bottom: 0;
    left: 0;
    .van-button {
      width: 100%;
    }
  }
  .nextBtn {
    width: 100%;
    padding: 12px;
    background: #f7f8f9;
    position: absolute;
    bottom: 0;
    left: 0;
    .left-btn{
      background: #fff;
      color:#333;
      border: 1px solid #E5E5E5;
      margin-right: 0.24rem;
    }
  }
  .btnBgSelect {
    width: 2.2rem;
    height: 1.2rem;
    background: url("../../assets/img/site/mySite_selete.png");
    background-size: 100% 100%;
    box-shadow: 0px 1px 5px 5px rgba(0, 0, 0, 0.05);
  }
  .btnBgNormal {
    width: 2.2rem;
    height: 1.2rem;
    background: url("../../assets/img/site/mySite_normol.png");
    background-size: 100% 100%;
  }
  .btnNumTitle {
    font-size: 25px;
    font-weight: bold;
    width: 100%;
    text-align: center;
    margin-top: 0.1rem;
    height: 30px;
  }
  .btnInfoTitle {
    font-size: 12px;
    font-weight: bold;
    width: 100%;
    text-align: center;
  }
  .VDS-ShadowBg_home {
    border-radius: 4px;
    box-shadow: 0px 2.5px 5px 5px rgba(0, 0, 0, 0.04);
  }
  .toStation {
    width: 72px;
    height: 24px;
    line-height: 24px;
    font-size: 12px;
    color: white;
    text-align: center;
    border-radius: 12px;
    background-color: #3f7c53;
    float: right;
  }
  .toLive {
    margin-right: 15px;
    width: 72px;
    height: 24px;
    line-height: 24px;
    font-size: 12px;
    color: #3f7c53;
    text-align: center;
    border-radius: 12px;
    background-color: rgba(63, 124, 83, 0.1);
    float: right;
  }
  .ps-wrap {
    height: 1rem;
    background: #ffffff;
    box-shadow: 0px 3px 22px 0px rgba(0, 0, 0, 0.07);
    border-radius: 0.16rem;
    padding: 0.3rem;
    margin-bottom: 0.4rem;
    justify-content: space-between;
    align-items: center;
    .ps-img {
      width: 0.64rem;
    }
    .ps-left {
      align-items: center;
      span {
        margin-left: 0.3rem;
        font-weight: bold;
        color: #272829;
      }
    }
    .ps-right {
      width: 0.5rem;
      height: 0.5rem;
      background: #f5f5f5;
      border-radius: 0.25rem;
      position: relative;
      i {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .van-dropdown-menu{
    margin: -10px -10px 10px;
  }
  .van-dropdown-menu__item{
    display: flex;
    width: 32%;
    height: 100%;
    justify-content: flex-start;
  }
  .van-dropdown-menu__bar{
    height: 0.76rem;
    line-height: 0.76rem;
  }
}
</style>
