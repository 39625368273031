<template>
  <div class="threeLevelMenu">
    <div class="overlay" v-if="overlayShow"></div>
    <div class="flex top-select">
        <div class="block">
          <div class="show-val flex">
            <div @click="oneClick" class="item">{{value1}}</div>
            <i class="icon-triangle"></i>
          </div>
          <div class="list" v-show="show1 && chooseOne == 1">
            <ul><li :class="{'selected': option.areaName == value1}" v-for="option in this.allList" :key="option.areaCode" @click="oneChange(option.areaCode)">{{ option.areaName }}</li></ul>
          </div>
        </div>
        <div class="block">
          <div class="show-val flex">
            <div @click="twoClick" class="item">{{value2}}</div>
            <i class="icon-triangle"></i>
          </div>
          <div class="list" v-show="show2 && chooseTwo == 1">
            <ul><li :class="{'selected': option.areaName == value2}" v-for="option in option2" :key="option.areaCode" @click="twoChange(option.areaCode)">{{ option.areaName }}</li></ul>
          </div>
        </div>
        <div class="block">
          <div class="show-val flex">
            <div @click="threeClick" class="item">{{value3}}</div>
            <i class="icon-triangle"></i>
          </div>
          <div class="list" v-show="show3 && chooseThree == 1">
            <ul><li :class="{'selected': option.areaName == value3}" v-for="option in option3" :key="option.areaCode" @click="threeChange(option.areaCode)">{{ option.areaName }}</li></ul>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import { getMyDeptTree, getMyAreaTree } from '@/utils/api'
import common from '@/utils/common'
export default {
  data() {
    return {
      value1: 0,
      value2: 0,
      value3: 0,
      option1: [],
      option2: [],
      option3: [],
      areaCode: '',
      tempDeptNoOne:'',
      tempDeptNoTwo: '',
      tempDeptNoThree: '',
      indexOne: 0,
      indexTwo: 0,
      indexThree: 0,
      allList:[],
      show1: false,
      show2: false,
      show3: false,
      chooseOne: null,
      chooseTwo: null,
      chooseThree: null,
      overlayShow: false,
    }
  },
  mounted() {
    this.toGetMyDeptTree()
  },
  methods:{
    oneChange(val){
      this.overlayShow = false
      this.show1 = false
      this.pageIndex = 1
      for(let [index,obj] of this.allList.entries()){
        if(obj.areaCode == val){
          this.indexTwo = index
          this.value1 = obj.areaName
        }
      }
      this.option2 = this.allList[this.indexTwo].child
      this.option3 = this.allList[this.indexTwo].child[0].child
      this.value2 = this.allList[this.indexTwo].child[0].areaName
      this.value3 = this.allList[this.indexTwo].child[0].child[0].areaName
      this.$emit('oneChange', val)
    },
    twoChange(val) {
      this.overlayShow = false
      this.show2 = false
      this.pageIndex = 1
      for(let [index,obj] of this.allList.entries()){
        if(this.indexTwo == index){
          for(let [i,value] of obj.child.entries()){
            if(value.areaCode == val){
              this.indexThree = i
              this.value2 = value.areaName
            }
          }
        }
      }
      this.option3 = this.allList[this.indexTwo].child[this.indexThree].child
      this.value3 = this.allList[this.indexTwo].child[this.indexThree].child[0].areaName
      this.$emit('twoChange', val)
    },
    threeChange(val) {
      this.overlayShow = false
      this.show3 = false
      this.pageIndex = 1
      for(let [index,obj] of this.allList.entries()){
        if(this.indexTwo == index){
          for(let [i,value] of obj.child.entries()){
            if(this.indexThree == i){
              for(let [n, m] of value.child.entries()){
                if(m.areaCode == val){
                  this.value3 = m.areaName
                }
              }
            }
          }
        }
      }
      this.$emit('threeChange', val)
    },
    oneClick() {
      if(this.chooseOne == 1){
         this.overlayShow = true
      }
      this.show1 = true
      this.show2 = false
      this.show3 = false 
    },
    twoClick() {
      if(this.chooseTwo == 1){
         this.overlayShow = true
      }
      this.show1 = false
      this.show2 = true
      this.show3 = false
    },
    threeClick() {
      if(this.chooseThree == 1){
         this.overlayShow = true
      }
      this.show1 = false
      this.show2 = false
      this.show3 = true
    },
    toGetMyDeptTree() {
      this.option1 = []
      this.option2 = []
      this.option3 = []
      getMyAreaTree({token: common.getItem('wtToken')}).then(res=>{
        if (res.code == 200) {
          if (res.data && res.data.businessCode == 200) {
            this.allList = res.data.data
            for(let obj of this.allList){
              this.chooseOne = obj.choose// 0：不可选  1：可选
              for(let o of obj.child){
                this.chooseTwo = o.choose// 0：不可选  1：可选
                for(let m of o.child){
                  this.chooseThree = m.choose// 0：不可选  1：可选
                }
              }
            }
            this.option2 = this.allList[0].child
            this.option3 = this.allList[0].child[0].child
            this.value1 = this.allList[0].areaName
            this.value2 = this.allList[0].child[0].areaName
            this.value3 = this.allList[0].child[0].child[0].areaName
          }
        }
      })
      
    },
  }
}
</script>

<style lang="less" scoped>
.threeLevelMenu{
  .flex {
    display: flex;
  }
  .block{
    flex: 1;
  }
  .overlay{
    width: 100%;
    min-height: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    position: absolute;
    z-index: 20;
    left: 0;
  }
  .icon-triangle{
    width: 0;
    height: 0;
    border-left: 0.06rem solid transparent;
    border-right: 0.06rem solid transparent;
    border-top: 0.12rem solid #3F7C53;
  }
  .top-select{
    height: 1rem;
    background: #fff;
    position: relative;
    z-index: 30;
    .show-val{
      height: 1rem;
      line-height: 1rem;
      width: 100%;
      text-align: center;
      align-items: center;
      font-size: 0.26rem;
      display: flex;
      justify-content: center;
      .item{
        padding-right: 0.1rem;
        color: #3F7C53;
      }
    }
    .list{
      z-index: 100;
      position: absolute;
      width: 100%;
      background: #fff;
      left: 0;
      top: 0.85rem;
      max-height: 6.4rem;
      overflow-y: auto;
      ol, ul, dl{
        margin-bottom: 0;
      }
      li{
        height: 0.7rem;
        line-height: 0.7rem;
        padding: 0 0.3rem;
      }
      .selected{ 
        color:#3F7C53;
      }
    }
  }
}
</style>
